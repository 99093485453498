import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Faustino Asia (Malaysia) Official',
      metaTags: [
        {
          name: 'description',
          content: 'Faustino Malaysia is the sole agent for Grupo Faustino wines in Malaysia. Directly from winery to Malaysia, we strive to be Malaysian\'s gateway to high quality Spanish wines. We carry a wide range from premium and vintage wines to more affordable options as well in order to cater to the tastes and preferences of all customer segments.'
        },
      ]
    }
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'About Us - Faustino Asia (Malaysia) Official',
      metaTags: []
    }
  },
  {
    path: '/our-wines',
    name: 'Wine',
    component: () => import(/* webpackChunkName: "our-wines" */ '../views/Wine.vue'),
    meta: {
      title: 'Our Wines - Faustino Asia (Malaysia) Official',
      metaTags: []
    }
  },
    {
      path: '/feature-wines',
      name: 'Feature',
      component: () => import(/* webpackChunkName: "contact" */ '../views/Feature.vue'),
      meta: {
        title: 'Our Wines - Faustino Asia (Malaysia) Official',
        metaTags: []
      }
    },
      /*
    {
      path: '/mid-autumn-charity',
      name: 'Charity',
      component: () => import(webpackChunkName: "contact" '../views/Charity.vue'),
      meta: {
        title: 'Autumn Charity - Faustino Asia (Malaysia) Official',
        metaTags: []
      }
    }
    */
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: 'history'
});


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else { // ← here ↓
    document.title = previousNearestWithMeta.meta.title
  }
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router
