<template>
  <div class="home" :style="{ backgroundImage: `url(${BgImage})` }" style="background-size: cover;">
    <div class="container main-container">
      <div class="row">
        <div class="col-12 wow fadeInDown">
          <img class="img-fluid grupo_logo" src="../assets/images/grupo_faustino.png" />
        </div>
      </div>
      <!-- Section One -->
      <section class="row">
        <div class="col-lg-4 col-md-4 col-4 wow fadeInLeft">
          <div class="row">
            <div class="col-12 section-one-wine-logo-col" v-for="wine in wine_selection_lists" :key="wine.id">
              <img class="img-fluid" @mouseover="hoverLogo(wine.id)" :src="wine.logo_url">
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-8 col-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s" style="position: relative">
          <div class="row">
            <div class="col-12" style="position: absolute">
              <transition name="slide-fade" v-for="wine in wine_selection_lists" :key="wine.id">
                <div class="img-cont" v-show="wine.show">
                  <a :href="wine.url"><img class="img-fluid zoom" :src="wine.wine_image"></a>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 wow fadeInRight">
          <div class="row">
            <div class="col-12">
              <img class="img-fluid faustino_avatar_logo" src="../assets/images/faustino_avatar_logo.png"/>
            </div>
            <div class="col-12">
              <p class="text-primary">Faustino Malaysia is the sole agent for Grupo Faustino wines in Malaysia. Directly from winery to Malaysia, we strive to be Malaysian's gateway to high quality Spanish wines. We carry a wide range from premium and vintage wines to more affordable options as well in order to cater to the tastes and preferences of all customer segments.</p>
            </div>
            <div class="col-12 d-lg-block d-md-none d-none">
              <transition name="slide-fade" v-for="wine in wine_selection_lists" :key="wine.id">
                <p v-if="wine.show" v-html="wine.desc" style="position: absolute"></p>
              </transition>
            </div>
          </div>
        </div>
        <ul class="section-one-social">
          <li><a target="_blank" href="https://www.facebook.com/Faustino-Malaysia-803982929793210/"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
          <li><a target="_blank" href="https://www.instagram.com/faustino.malaysia/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
        </ul>
      </section>
      <div class="spacing"></div>
      <!-- Section Two -->
      <section class="row">
        <div class="col-12 wow fadeInDown">
          <h1>Wine Selections</h1>
        </div>
        <div class="col-12 d-none d-md-none d-lg-block wow fadeInDown" data-wow-delay="0.3s">
          <div class="row" v-if="image_selection_lists.length > 0">
            <div class="col-md-3 col-6" v-for="selection in image_selection_lists" :key="selection.id">
              <a :href="selection.url"><img class="img-fluid wine-selection-img" @mouseover="hover(selection.id)" :src="selection.image_url"></a>
            </div>
          </div>
        </div>
        <div class="col-12 d-block d-md-block d-lg-none wow fadeInDown" data-wow-delay="0.3s">
          <div class="row">
            <div class="col-md-3 col-6">
              <img class="img-fluid wine-selection-img" src="../assets/images/selections/RED_hover.png">
            </div>
            <div class="col-md-3 col-6">
              <img class="img-fluid wine-selection-img white-wine" src="../assets/images/selections/WHITE_hover.png">
            </div>
            <div class="col-md-3 col-6">
              <img class="img-fluid wine-selection-img" src="../assets/images/selections/ROSE_hover.png">
            </div>
            <div class="col-md-3 col-6">
              <img class="img-fluid wine-selection-img" src="../assets/images/selections/SPARKLING_hover.png">
            </div>
          </div>
        </div>
      </section>
      <div class="spacing"></div>
      <!-- Section Three -->
      <section class="row">
        <div class="col-12 wow fadeInDown">
          <h1>Latest In Faustino</h1>
        </div>
        <div class="col-12 wow fadeInDown" data-wow-delay="0.3s">
          <carousel :per-page="1" :adjustable-height="true" :center-mode="true" :navigation-enabled="true" :pagination-active-color="'#BD9E57'">
            <slide>
              <a href="https://trinks.com.my/product-category/bodegas-portia">
                <img class="img-fluid slider-img" src="../assets/images/events/website_pop_up_PORTIA_10TH_ANNIVER.png"/>
              </a>
            </slide>
            <!-- First Slider -->
            <slide>
              <img class="img-fluid slider-img" src="../assets/images/latest/GRUPOF_JM.jpg"/>
            </slide>
            <!-- Second Slider -->
            <slide>
              <img class="img-fluid slider-img" src="../assets/images/latest/GRUPOF_DECANTER.jpg"/>
            </slide>
            <!-- Third Slider -->
            <slide>
              <div class="row">
                <!-- slider left -->
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12" style="margin-bottom: 25px;">
                      <img class="img-fluid" src="../assets/images/sliders/fuastino-eneko-trademark.png">
                    </div>
                  </div>
                </div>

                <!-- slider right -->
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="year-title">2015</div>
                      <h2>Faustino & Eneko ATXA</h2>
                      <p>An excellent pairing between one of the most international brands and the recognition of a four stars Michelin - Eneko Atxa.</p>
                      <p>This wine will delight the hearts and mouths of the wine and gastronomy enthusiast.</p>
                      <div class="inner-spacing"></div>
                    </div>

                    <div class="col-md-12">
                      <div class="inner-title">Awards :</div>
                    </div>
                    <div class="col-md-12" style="margin-bottom: 15px">
                      <img class="img-fluid" src="../assets/images/sliders/cRelease-awards.png">
                    </div>
                    <div class="col-md-12">
                      <div class="inner-title">Varietal & Ageing :</div>
                    </div>

                    <div class="col-md-12" style="margin-bottom: 15px">
                      <div class="row">
                        <div class="col-6 col-md-6">
                          <div class="row">
                            <div class="col-3 col-md-3">
                              <img class="img-fluid" src="../assets/images/sliders/grape-logo.png">
                            </div>
                            <div class="col-9 col-md-9">
                              <div class="v-and-a-percent">85%</div>
                              <div class="v-and-a-title">Tempranillo</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-6">
                          <div class="row">
                            <div class="col-12 col-md-12">
                              <div class="v-and-a-percent">15%</div>
                              <div class="v-and-a-title">Graciano</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-6 col-md-6">
                          <div class="row">
                            <div class="col-3 col-md-3">
                              <img class="img-fluid" src="../assets/images/sliders/barrels-logo.png">
                            </div>
                            <div class="col-9 col-md-9">
                              <div class="v-and-a-percent">8 MONTHS</div>
                              <div class="v-and-a-title">In FRENCH OAK BARRELS</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </section>
      <div class="spacing"></div>
      <!-- Section Four -->
      <section class="row">
        <div class="col-12 wine-logo-col">
          <div class="row wine-logo-row">
            <div class="col-md-2 col-4 wine-logo-each-col wow fadeInUp" data-wow-delay="0.1s">
              <img class="img-fluid" src="../assets/images/wine_logos/new.logo.faustino.active.png">
            </div>
            <div class="col-md-2 col-4 wine-logo-each-col wow fadeInUp" data-wow-delay="0.2s">
              <img class="img-fluid" src="../assets/images/wine_logos/new.logo.campillo.active.png">
            </div>
            <div class="col-md-2 col-4 wine-logo-each-col wow fadeInUp" data-wow-delay="0.3s">
              <img class="img-fluid" src="../assets/images/wine_logos/new.logo.portia.active.png">
            </div>
            <div class="col-md-2 col-4 wine-logo-each-col wow fadeInUp" data-wow-delay="0.4s">
              <img class="img-fluid" src="../assets/images/wine_logos/new.logo.valcarlos.active.png">
            </div>
            <div class="col-md-2 col-4 wine-logo-each-col wow fadeInUp" data-wow-delay="0.5s">
              <img class="img-fluid" src="../assets/images/wine_logos/new.logo.vitoria.active.png">
            </div>
            <div class="col-md-2 col-4 wine-logo-each-col wow fadeInUp" data-wow-delay="0.6s">
              <img class="img-fluid" src="../assets/images/wine_logos/new.logo.leganza.active.png">
            </div>
          </div>
        </div>
      </section>
      <div class="spacing"></div>
      <!-- Modal -->
      <!--<div class="modal fade" id="event_banner">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body d-none d-lg-block">
              <button type="button" class="close" aria-label="Close" @click="closeModal">
                <span aria-hidden="true">&times;</span>
              </button>
              <span v-if="moment().isAfter('2022-06-25')">
                <a href="https://trinks.com.my/wines">
                  <img class="img-fluid mb-3" src="../assets/images/events/Website_Banner_4th_ANNI.jpg">
                </a>
              </span>
              <a href="https://trinks.com.my/product/hero-bundle">
                <img class="img-fluid mb-3" src="../assets/images/events/Father_Day_banner.jpg">
              </a>
            </div>
            <div class="modal-body modal-dialog-centered d-block d-lg-none mobile-body">
              <button type="button" class="close" aria-label="Close" @click="closeModal">
                <span aria-hidden="true">&times;</span>
              </button>
              <span v-if="moment().isAfter('2022-06-25')">
                <a href="https://trinks.com.my/wines">
                  <img class="img-fluid mb-3" src="../assets/images/events/Website_Banner_4th_ANNI_Mobile.jpg">
                </a>
              </span>
              <a href="https://trinks.com.my/product/hero-bundle">
                <img class="img-fluid mb-3" src="../assets/images/events/Father_Day_banner_mobile.jpg">
              </a>
            </div>
          </div>
        </div>
      </div>-->
    </div>s
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import BgImage from '../assets/images/bg-1.png'
import {WOW} from "wowjs";

export default {
  name: 'Home',
  components: {

  },
  data () {
    return {
      BgImage,
      wines: [
        {id: 0, 'name': 'faustino', 'is_selected': 'active', 'show': true, 'url': 'https://trinks.com.my/product/faustino-i-granreserva-2008', 'desc': 'Clean, bright. Ruby colour developing to maroon. On the nose is complex and elegant, wide fruity and spicy notes. On the mouth, elegant, with pleasant hints due to the barrel ageing. Long and silky finish.\n' +
                  '\n' +
                  'It matches with any kind of meat and mushrooms rice. Some tasty or oily fish such as tuna or cod. Medium-aged cheese such as emmental or gruyere.'},
        {id: 1, 'name': 'campillo', 'is_selected': 'disable', 'show': false, 'url': 'https://trinks.com.my/product/campillo-rosado', 'desc': 'Clean, bright, pretty pale pink salmon. Very intense, fruity character with a floral notes. Delicate, fine, very subtle aromas of redcurrants and lilacs. In the background, notes of tropical fruit.\n' +
                '\n' +
                'Pleasant on the palate, good acidity very well integrated that prolongs the freshness in the mouth. Medium long. Fresh. The fruity aromas remain present.\n' +
                '\n' +
                'It matches with cold meat, sausages, pork or chicken. Grilled meat and mushrooms. Quiche Lorraine. Rice and pasta.'},
        {id: 2, 'name': 'portia', 'is_selected': 'disable', 'show': false, 'url': 'https://trinks.com.my/product/portia-prima', 'desc': 'Clean, bright, intense cherry red, with ruby ​​evolution. Complex, warm, notes of vanilla, black fruit and cocoa in the background. Tasty, structured and very balanced. Very long finish with barrel and black fruit sensations.\n' +
                  '\n' +
                  'It is easy to pair with any type of meat. Grilled mushrooms. Some very flavorful or fatty fish, such as tuna or cod. Emmental or Gruyère cheeses. Good with rice with mushrooms.'},
        {id: 3, 'name': 'valcarlos', 'is_selected': 'disable', 'show': false, 'url': 'https://trinks.com.my/product/marques-de-valcarlos-roble', 'desc': 'Clean, bright, cherry red developing to maroon. Nose: On the palate is intense, marked by sweet notes due to the barrel ageing well integrated with ripe fruits hints. Tasting: Light on the palate, good tannin, pleasant acidity and homogeneous. Finish: Pleasant finish with marked barrel notes.\n' +
                  '\n' +
                  'Matches with any kind of meat. It matches with grilled or fried potatoes, mushrooms or ham. Some tasty or oily fish such as tuna, cod. Medium-aged cheese and berry dessert. Rice and all kind of pasta.'},
        {id: 4, 'name': 'vitoria', 'is_selected': 'disable', 'show': false, 'url': 'https://trinks.com.my/product/marques-vitoria-gran-reserva', 'desc': 'Clean, bright, cherry red colour developing to maroon. Complex, intense. Well integrated aromas of ripe fruits and barrel notes. Spicy notes. Elegant, round, very balanced. Pleasant on the palate, smooth and fine. Refined tannin. Long finish, sweet hints. Wood and toasted notes.\n' +
                  '\n' +
                  'It matches with any kind of meat and mushrooms rice. Some tasty or oily fish such as tuna or cod. Medium-aged cheese such as emmental or gruyere.'},
        {id: 5, 'name': 'leganza', 'is_selected': 'disable', 'show': false, 'url': 'https://trinks.com.my/product/cdl-tempranillo', 'desc': 'Clean, bright, cherry red developing to burgundy. Intense, marked by spices (clove), well integrated with ripe fruits hints and black olive notes. Good structure, marked acidity and balanced. Medium finish, fruity.\n' +
                  '\n' +
                  'Any kind of meat. It matches with grilled or fried potatoes, mushrooms or ham. Some tasty or oily fish such as tuna, cod. Medium-aged cheese and berry dessert. Rice and all kind of pasta.'}
      ],
      selections: [
        {id: 0, 'image_name': 'RED', 'is_hover': 'disable', 'url': 'https://trinks.com.my/product/faustino-eneko'},
        {id: 1, 'image_name': 'WHITE', 'is_hover': 'disable', 'url': 'https://trinks.com.my/product/portia-verdejo'},
        {id: 2, 'image_name': 'ROSE', 'is_hover': 'disable', 'url': 'https://trinks.com.my/product/campillo-rosado'},
        {id: 3, 'image_name': 'SPARKLING', 'is_hover': 'disable', 'url': 'https://trinks.com.my/product/cava-faustino-brut-reserva'}
      ],
      image_selection_lists: [],
      wine_selection_lists: []
    }
  },
  mounted() {

    //console.log(this.moment())
    /*
    this.$nextTick(() => {
      this.$(document).ready(function() {
        alert('test');
      })
    })
    */
       //let me = this;

        /*
       me.$('#event_banner').modal('show');

       this.$('html').click(function(){
         me.$('#event_banner').modal('hide');
         me.$('body').removeClass('modal-open');
         me.$('.modal-backdrop').remove();
       });
         */


    const wow = new WOW();

    wow.init();

    this.wines.forEach((wine) => {
      this.wine_selection_lists.push({
        id: wine.id,
        'logo_url': require('../assets/images/wine_logos/new.logo.'+ wine.name + '.'+ wine.is_selected +'.png'),
        'wine_image': require('../assets/images/wines/'+ wine.name +'.png'),
        'show': wine.show,
        'url': wine.url,
        'desc': wine.desc
      })
    });


    for (let i = 0; i < this.selections.length; i++)
    {
      this.image_selection_lists.push({id: this.selections[i].id, 'image_url': require('../assets/images/selections/'+ this.selections[i].image_name + '_'+ this.selections[i].is_hover +'.png'), 'url': this.selections[i].url})
    }
  },
  methods: {
    hover: function(id) {
      for (let i = 0; i < this.selections.length; i++)
      {
        if (this.selections[i].id === id)
        {
          this.selections[i].is_hover = 'hover';
        }
        else {
          this.selections[i].is_hover = 'disable';
        }
      }

      this.image_selection_lists = [];
      for (let i = 0; i < this.selections.length; i++)
      {
        this.image_selection_lists.push({id: this.selections[i].id, 'image_url': require('../assets/images/selections/'+ this.selections[i].image_name + '_'+ this.selections[i].is_hover +'.png'), 'url': this.selections[i].url})
      }
    },
    hoverLogo: function (id) {

      this.show = true;
      for (let i = 0; i < this.wines.length; i++)
      {
        if (this.wines[i].id === id)
        {
          this.wines[i].is_selected = 'active';
          this.wines[i].show = true;
        }
        else {
          this.wines[i].is_selected = 'disable';
          this.wines[i].show = false;
        }
      }

      this.wine_selection_lists = [];
      for (let i = 0; i < this.wines.length; i++)
      {
        this.wine_selection_lists.push({
          id: this.wines[i].id,
          'logo_url': require('../assets/images/wine_logos/new.logo.'+ this.wines[i].name + '.'+ this.wines[i].is_selected +'.png'),
          'wine_image': require('../assets/images/wines/'+ this.wines[i].name +'.png'),
          'show': this.wines[i].show,
          'url': this.wines[i].url,
          'desc': this.wines[i].desc
        })
      }
    },
    closeModal () {
      this.$('#event_banner').modal('hide');
      this.$('body').removeClass('modal-open');
      this.$('.modal-backdrop').remove();
    }
  }
}
</script>
