import Vue from 'vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
import 'popper.js'
import 'bootstrap'
import './assets/scss/app.scss'
import VueCarousel from 'vue-carousel';
import moment from 'moment';

Vue.use(VueCarousel);

Vue.use({
  install: function(Vue){
    Vue.prototype.$ = jQuery;
    Vue.prototype.moment = moment;
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
